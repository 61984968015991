import React from 'react';

import SEO from '../components/seo';
import NavBar from '../components/navBar';
import Footer from '../components/Footer';
import MyLink from '../components/MyLink';

// styles
const pageStyles = {
  color: '#232129',
  // padding: 96,
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};

const IndexPage = () => {
  return (
    <div style={pageStyles}>
      <SEO title="Privacy Policy" />
      <NavBar />
      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="relative max-w-7xl mx-auto text-base text-gray-800 text-l">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl">PRIVACY POLICY</h1>
          <p className="mt-3 text-gray-800 sm:mt-4">
            This Privacy Policy governs the manner in which One Step LLC collects, uses, maintains and discloses
            information collected from users (each, a “User”) of the one-step.io platform including associated
            applications and websites (“App”). This privacy policy applies to the App and all products and services
            offered by One Step LLC.
          </p>
          <p>&nbsp;</p>
          <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl">Overview</h2>
          <p>
            We avoid collecting personal information whenever possible. Your data is yours and often doesn't even leave
            your device. Any data stored on your device is encrypted.
          </p>
          <p>&nbsp;</p>
          <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl">
            Sensitive and Personal Identification Information
          </h2>
          <p>
            We may collect sensitive or personal identification information from Users in a variety of ways, including
            but not limited to when Users: visit our App, register on the App, place an order, fill out a form, respond
            to a survey, and in connection with other activities, services, features or resources we make available on
            our App. Users may be asked for, as appropriate: name & email address.{' '}
            {/* mailing address, phone number, payment
            information, device location, and camera access. */}
            We will collect sensitive and personal identification information from Users only if they voluntarily submit
            such information to us. Users can always refuse to supply sensitive or personally identification
            information. Refusal to provide certain information may prevent Users from engaging in certain App related
            activities.
          </p>
          <p>&nbsp;</p>
          <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl">
            Non-personal identification information
          </h2>
          <p>
            We may collect non-personal identification information about Users whenever they interact with our App.
            Non-personal identification information may include the usage data and diagnostics data such as browser
            name, the type of computer and technical information about Users means of connection to our App, such as the
            operating system and the Internet service providers utilized and other similar information.
          </p>
          <p>&nbsp;</p>
          <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl">
            How we use collected information
          </h2>
          <p>One Step LLC may collect and use Users’ personal information for the following purposes:</p>
          <ul className="custom-list">
            <li className="list-disc mt-2">
              <h3 className="font-bold">To run and operate our App.</h3>
              <p>We may need your information display content on the App correctly.</p>
            </li>
            <li className="list-disc mt-2">
              <h3 className="font-bold">To improve customer service.</h3>
              <p>
                Information you provide helps us respond to your customer service requests and support needs more
                efficiently.
              </p>
            </li>
            <li className="list-disc mt-2">
              <h3 className="font-bold">To personalize user experience.</h3>
              <p>
                We may use information in the aggregate to understand how our Users as a group use the services and
                resources provided on our App.
              </p>
            </li>
            <li className="list-disc mt-2">
              <h3 className="font-bold">To improve our App.</h3>
              <p>We may use feedback you provide to improve our products and services.</p>
            </li>
            {/* <li className="list-disc mt-2">
              <h3 className="font-bold">To process payments.</h3>
              <p>
                We may use the information Users provide about themselves when placing an order only to provide service
                to that order. We do not share this information with outside parties except to the extent necessary to
                provide the service.
              </p>
            </li>
            <li className="list-disc mt-2">
              <h3 className="font-bold">To run a promotion, contest, survey or other App feature.</h3>
              <p>To send Users information they agreed to receive about topics we think will be of interest to them.</p>
            </li>
            <li className="list-disc mt-2">
              <h3 className="font-bold">To manage product reviews and ratings.</h3>
              <p>
                We may use third party service providers to help us manage product reviews and ratings left by Users.
              </p>
            </li>
            <li className="list-disc mt-2">
              <h3 className="font-bold">To send periodic emails.</h3>
              <p>
                We may use the email address to send User information and updates pertaining to their order. It may also
                be used to respond to their inquiries, questions, and/or other requests.
              </p>
            </li> */}
          </ul>
          <p>&nbsp;</p>
          {/* <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl">
            How we protect your information
          </h2>
          <p>
            We adopt appropriate data collection, storage and processing practices and security measures to protect
            against unauthorized access, alteration, disclosure or destruction of your personal information, username,
            password, transaction information and data stored on our App.
          </p>
          <p>
            Sensitive and private data exchange between the App and its Users happens over a SSL secured communication
            channel and is encrypted and protected with digital signatures.
          </p>
          <p>&nbsp;</p> */}
          <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl">
            Sharing your personal information
          </h2>
          <p>
            We do not sell, trade, or rent Users personal identification information to others. Usually, we don't even
            collect personal information.
            {/* We may share generic aggregated demographic information not linked to any personal identification information regarding visitors
            and users with our business partners, trusted affiliates and advertisers for the purposes outlined above. We
            may use third party service providers to help us operate our business and the App or administer activities
            on our behalf, such as sending out newsletters or surveys. We may share your information with these third
            parties for those limited purposes provided that you have given us your permission. */}
          </p>
          <p>&nbsp;</p>
          {/* <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl">Electronic newsletters</h2>
          <p>
            If User decides to opt-in to our mailing list, they will receive emails that may include company news,
            updates, related product or service information, etc. We may use third party service providers to help us
            operate our business and the App or administer activities on our behalf, such as sending out newsletters or
            surveys. We may share your information with these third parties for those limited purposes provided that you
            have given us your permission.
          </p>
          <p>&nbsp;</p> */}
          {/* <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl">Third party websites</h2>
          <p>
            Users may find advertising or other content on our App that link to the sites and services of our partners,
            suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links
            that appear on these sites and are not responsible for the practices employed by websites linked to or from
            our App. In addition, these sites or services, including their content and links, may be constantly
            changing. These sites and services may have their own privacy policies and customer service policies.
            Browsing and interaction on any other website, including websites which have a link to our App, is subject
            to that website’s own terms and policies.
          </p>
          <p>&nbsp;</p> */}
          <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl">
            Changes to this privacy policy
          </h2>
          <p>
            One Step LLC has the discretion to update this privacy policy at any time.
            {/* When we do, we will post a prominent notification on our App and/or send a notification to the email address associated with a user’s
            account. We encourage Users to frequently check this page for any changes to stay informed about how we are
  helping to protect the personal information we collect.*/}{' '}
            You acknowledge and agree that it is your responsibility to review this privacy policy periodically and
            become aware of modifications.
          </p>
          <p>&nbsp;</p>
          <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl">
            Your acceptance of these terms
          </h2>
          <p>
            By using this App, you signify your acceptance of this policy. If you do not agree to this policy, please do
            not use our App. Your continued use of the App following the posting of changes to this policy will be
            deemed your acceptance of those changes.
          </p>
          <p>&nbsp;</p>
          <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl">Contacting us</h2>
          <p>
            If you have any questions about this Privacy Policy, the practices of any App, or your dealings with this
            App, please{' '}
            <MyLink
              href="mailto:support@one-step.io?&subject=Support - Privacy"
              linkType="event_link"
              eventName="privacy_support_clicked"
              className="text-blue-600 hover:text-gray-900"
            >
              contact us.
            </MyLink>
            <br />
            This document was last updated on Jan 9, 2023.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IndexPage;
